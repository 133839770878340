import React from 'react';

import DashboardStatCard from './DashboardStatCard';
import { calculateCurrencySpentByCurrencyType } from '../../../utils/aggregations';
import { CurrencyType } from '../../../types/enums';
import { Invoice } from '../../../types/interfaces';
import CurrencyNumber from '../../../utils/CurrencyNumber';

const ContributionsToDateCurrencySpent = (props: { invoices: Invoice[] }) => {
  const currencySpentByCurrencyType: Record<
    CurrencyType,
    { totalCurrencySpent: number; totalCurrencySpentCurrentYear: number }
  > = calculateCurrencySpentByCurrencyType(props.invoices);

  const data = {
    label: 'Contributions to date ($/€/£)',
    tabs: Object.entries(currencySpentByCurrencyType).map(([key, value]) => {
      const totalCurrencySpent = new CurrencyNumber(
        Math.round(value.totalCurrencySpent),
        key as CurrencyType,
        true,
      );
      const totalCurrencySpentCurrentYear = new CurrencyNumber(
        Math.round(value.totalCurrencySpentCurrentYear),
        key as CurrencyType,
        true,
      );
      return {
        listTitle: key,
        statNumber: totalCurrencySpent.toString(),
        isIncrease: totalCurrencySpentCurrentYear.currencyAmount >= 0,
        changeAmountString: `${totalCurrencySpentCurrentYear.toString()} in ${new Date().getFullYear()}`,
      };
    }),
  };

  return <DashboardStatCard label={data.label} tabs={data.tabs} />;
};

export default ContributionsToDateCurrencySpent;
