import React, { useEffect, useState } from 'react';
import { Heading, Stack } from '@chakra-ui/react';

import AchievementsSection from './AchievementsSection';
import BasePage from '../../components/BasePage';
import { UserBadge, UserBadgeInfo } from '../../types/interfaces';
import { Auth0ContextInterface, withAuth0 } from '@auth0/auth0-react';
import { getUserBadges, userBadgeSeen } from '../../services/user_badges';
import { UserBadgeType } from '../../types/enums';
import LoadingWrapper from '../../components/LoadingWrapper';

type AchievementsPageState = {
  badgeInfoData: {
    badgeTypeToInfo: Record<UserBadgeType, UserBadgeInfo>;
    badgeCategoryToBadgeInfos: Record<string, UserBadgeInfo[]>;
    isLoading: boolean;
  };
  badgesEarnedData: {
    badges: UserBadge[];
    isLoading: boolean;
  };
};

const badgeCategories = [
  'Milestones',
  'Choice of removal',
  'Tonnes carbon removed',
  'Total value of contributions',
  'Climate Courses',
];

const AchievementsPage = (props: { auth0: Auth0ContextInterface }) => {
  const [state, setState] = useState<AchievementsPageState>({
    badgeInfoData: {
      badgeTypeToInfo: {} as Record<UserBadgeType, UserBadgeInfo>,
      badgeCategoryToBadgeInfos: {} as Record<string, UserBadgeInfo[]>,
      isLoading: true,
    },
    badgesEarnedData: {
      badges: [],
      isLoading: true,
    },
  });

  // Fetch User Badges static info
  useEffect(() => {
    fetch('/data/userBadgesData.json')
      .then((response) => response.json())
      .then((badgeInfos: UserBadgeInfo[]) => {
        const badgeTypeToInfo = badgeInfos.reduce(
          (acc, badgeInfo) => ({
            ...acc,
            [badgeInfo.badgeType]: badgeInfo,
          }),
          {} as Record<UserBadgeType, UserBadgeInfo>,
        );

        const badgeCategoryToBadgeInfos = badgeInfos.reduce(
          (acc, badgeInfo) => {
            const { badgeCategory } = badgeInfo;

            if (!acc[badgeCategory]) {
              acc[badgeCategory] = [];
            }

            acc[badgeCategory].push(badgeInfo);

            return acc;
          },
          {} as Record<string, UserBadgeInfo[]>,
        );

        setState((prevState) => ({
          ...prevState,
          badgeInfoData: {
            badgeTypeToInfo,
            badgeCategoryToBadgeInfos,
            isLoading: false,
          },
        }));
      })
      .catch((error) => {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          badgeInfoData: { ...prevState.badgeInfoData, isLoading: false },
        }));
      });
  }, [props.auth0, setState]);

  // Fetch User Badge data
  useEffect(() => {
    const markBadgesAsSeen = async (badges: UserBadge[], token: string) => {
      const unseenBadges = badges.filter((badge: UserBadge) => !badge.seen);
      const unseenBadgeIds = unseenBadges.map((badge: UserBadge) => badge.id);
      Promise.all(
        unseenBadgeIds.map((badgeId: number) => userBadgeSeen(token, badgeId)),
      );
    };

    const fetchData = async () => {
      try {
        const token = await props.auth0.getAccessTokenSilently();

        const response = await getUserBadges(token);

        const badges = await response.json();

        if (response.ok) {
          setState((prevState) => ({
            ...prevState,
            badgesEarnedData: { badges, isLoading: false },
          }));

          markBadgesAsSeen(badges, token);
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };

    fetchData();
  }, [props.auth0, setState]);

  return (
    <BasePage>
      <Stack spacing={16}>
        <Heading as="h2" size="lg">
          Achievements
        </Heading>

        <LoadingWrapper
          isLoading={
            state.badgeInfoData.isLoading || state.badgesEarnedData.isLoading
          }
        >
          {badgeCategories &&
            badgeCategories.map((category) => (
              <AchievementsSection
                key={category}
                category={category}
                badgesInfo={
                  state.badgeInfoData.badgeCategoryToBadgeInfos[category]
                }
                badgesEarned={state.badgesEarnedData.badges}
              />
            ))}
        </LoadingWrapper>
        {/* <Text pt="1vh" pb="5vh" fontSize="md">
          Customize your dashboard settings.
        </Text> */}
        {/* <Center alignItems="center" width="100%">
          <Profile />
        </Center> */}
      </Stack>
    </BasePage>
  );
};

export default withAuth0(AchievementsPage);
