import React from 'react';

import DashboardStatCard from './DashboardStatCard';
import { calculateMonthlySpendByCurrencyType } from '../../../utils/aggregations';
import { CurrencyType } from '../../../types/enums';
import { Subscription } from '../../../types/interfaces';
import CurrencyNumber from '../../../utils/CurrencyNumber';

const ProjectedCurrencySpent = (props: {
  subscriptions: Subscription[];
  numberMonthsToProject: number[];
}) => {
  const monthlySpend: Record<CurrencyType, number> =
    calculateMonthlySpendByCurrencyType(props.subscriptions);
  const subscriptionCurrencies = Object.keys(monthlySpend);
  if (subscriptionCurrencies.length > 1) {
    console.error(
      'Multiple currencies found in subscriptions, not able to handle this... Using first currency',
    );
  }
  const currency =
    props.subscriptions.length > 0
      ? props.subscriptions[0].currencyType
      : CurrencyType.USD;
  const currencyAmountPerMonth = monthlySpend[currency] || 0;

  const data = {
    label: 'Projected Contributions ($/€/£)',
    tabs: props.numberMonthsToProject.map((months) => ({
      listTitle: `${months} mths`,
      statNumber: new CurrencyNumber(
        months * currencyAmountPerMonth,
        currency,
        true,
      ).toString(),
      isIncrease: true,
    })),
  };

  return <DashboardStatCard label={data.label} tabs={data.tabs} />;
};

export default ProjectedCurrencySpent;
