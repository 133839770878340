export enum SolutionType {
  BIOCHAR_AND_BICRS = 'BIOCHAR_AND_BICRS',
  BIO_OIL = 'BIO_OIL',
  BIODIVERSITY_AND_AVOIDED_DEFORESTATION = 'BIODIVERSITY_AND_AVOIDED_DEFORESTATION',
  DIRECT_AIR_CAPTURE = 'DIRECT_AIR_CAPTURE',
  ENHANCED_ROCK_WEATHERING = 'ENHANCED_ROCK_WEATHERING',
  OCEAN_CARBON_REMOVAL = 'OCEAN_CARBON_REMOVAL',
  REFORESTATION_AND_AFFORESTATION = 'REFORESTATION_AND_AFFORESTATION',
  SOIL_CARBON_REMOVAL = 'SOIL_CARBON_REMOVAL',
}

export enum CurrencyType {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  CAD = 'cad',
  AUD = 'aud',
}

export enum SubscriptionStatus {
  Active = 'active',
  Paused = 'paused',
  Cancelled = 'cancelled',
}

export enum UserBadgeType {
  // Milestones
  OFF_TO_THE_RACES = 'OFF_TO_THE_RACES',
  CARBON_EXPLORER = 'CARBON_EXPLORER',
  CARBON_COMMITTED = 'CARBON_COMMITTED',
  CARBON_REMOVAL_LEADER = 'CARBON_REMOVAL_LEADER',
  ECO_ADVOCATE = 'ECO_ADVOCATE',
  CARBON_CHAMPION = 'CARBON_CHAMPION',
  CARBON_VISIONARY = 'CARBON_VISIONARY',
  HAPPY_CARBON_BIRTHDAY = 'HAPPY_CARBON_BIRTHDAY',
  SUSTAINABLE_STEWARD = 'SUSTAINABLE_STEWARD',
  CLIMATE_VETERAN = 'CLIMATE_VETERAN',

  // Choice of removal
  MONTHLY_CLEANING = 'MONTHLY_CLEANING',
  FIRST_IMPACT = 'FIRST_IMPACT',
  FIVE_TIME_IMPACT = 'FIVE_TIME_IMPACT',

  // Tonnes
  KILOGRAM_WARRIOR = 'KILOGRAM_WARRIOR',
  HALF_TONNE_HERO = 'HALF_TONNE_HERO',
  ONE_TONNE_FIGHTER = 'ONE_TONNE_FIGHTER',
  TONNAGE_TITAN = 'TONNAGE_TITAN',
  TRIPLE_TONNE_REMOVER = 'TRIPLE_TONNE_REMOVER',
  FOUR_TONNE_NEUTRALIZER = 'FOUR_TONNE_NEUTRALIZER',
  EURO_ACTIVIST = 'EURO_ACTIVIST',
  AMERICAN_ADVOCATE = 'AMERICAN_ADVOCATE',

  // Total value of contributions
  IMPACT_INVESTOR_LEVEL_1 = 'IMPACT_INVESTOR_LEVEL_1',
  IMPACT_INVESTOR_LEVEL_2 = 'IMPACT_INVESTOR_LEVEL_2',
  IMPACT_INVESTOR_LEVEL_3 = 'IMPACT_INVESTOR_LEVEL_3',
  IMPACT_INVESTOR_LEVEL_4 = 'IMPACT_INVESTOR_LEVEL_4',
}
