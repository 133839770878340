import { apiOrigin } from './constants';
import { callApi } from './utils';

export const userBadgesEndpoint = `${apiOrigin}/user-badges/`;

export async function getUserBadges(accessToken: string) {
  return callApi(accessToken, `${userBadgesEndpoint}me/`, 'GET');
}

export async function userBadgeSeen(accessToken: string, badgeId: number) {
  return callApi(accessToken, `${userBadgesEndpoint}${badgeId}/seen/`, 'POST');
}
