import { Badge } from '@chakra-ui/react';

const UnseenAchievementBadge = () => {
  return (
    <Badge
      colorScheme="green"
      position="absolute"
      variant="solid"
      paddingX="8px"
      paddingY="4px"
      borderRadius="lg"
      top={2}
      right={2}
      zIndex={1}
      textTransform="none" // Remove uppercase
    >
      New
    </Badge>
  );
};

export default UnseenAchievementBadge;
