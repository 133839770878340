import { Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';

import { UserBadge, UserBadgeInfo } from '../../types/interfaces';
import AchievementCard from './AchievementCard';

const AchievementsSection = (props: {
  category: string;
  badgesEarned: UserBadge[];
  badgesInfo: UserBadgeInfo[];
}) => {
  return (
    <Stack spacing={6}>
      <Heading as="h3" size="md">
        {props.category}
      </Heading>
      {props.category === 'Climate Courses' && (
        <Text>
          <em>Coming soon...</em>
        </Text>
      )}

      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, xl: 4 }} spacing={8}>
        {props.badgesInfo.map((badgeInfo) => {
          const badgeEarned = props.badgesEarned.find(
            (badge) => badgeInfo.badgeType === badge.badgeType,
          );
          return (
            <AchievementCard
              key={badgeInfo.name}
              badgeEarned={badgeEarned}
              badgeInfo={badgeInfo}
            />
          );
        })}
      </SimpleGrid>
    </Stack>
  );
};

export default AchievementsSection;
