import { ReactNode } from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const scopes = [
  'view:invoice',
  'view:invoice_contribution',
  'view:subscription',
  'create:subscription',
  'update:subscription',
  'delete:subscription',
  'view:user',
  'view:user_badge',
  'openid',
  'profile',
  'email',
];

const Auth0ProviderWithNavigate = (props: { children: ReactNode }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (
    domain === undefined ||
    clientId === undefined ||
    audience === undefined
  ) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        connection: 'email',
        redirect_uri: window.location.origin,
        scope: scopes.join(' '),
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {props.children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
