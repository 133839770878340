import React from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
} from 'victory';

import { Invoice } from '../../types/interfaces';
import { formatDate } from '../../utils/dateUtils';

type ProcessedData = {
  [date: string]: number;
};

// Process the data
const processData = (data: Invoice[]) => {
  const sortedData = data.sort(
    (a: Invoice, b: Invoice) =>
      new Date(a.purchaseDate).getTime() - new Date(b.purchaseDate).getTime(),
  );
  const processedData: ProcessedData = {};
  let totalKG: number = 0;

  sortedData.forEach((item) => {
    const date = new Date(item.purchaseDate);
    const formattedDate = formatDate(date);
    totalKG += item.kgCarbonRemoval;
    processedData[formattedDate] = totalKG;
  });

  return processedData;
};

const sharedLabelStyles = {
  fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
};
const sharedAxisStyles = {
  tickLabels: {
    fontSize: 12,
  },
  axisLabel: {
    padding: 35,
    fontSize: 15,
  },
};

const DashboardContributionChart = ({ data }: { data: Invoice[] }) => {
  const processedData = processData(data);
  const dataPoints = Object.entries(processedData).map((entry) => {
    return {
      x: new Date(entry[0]),
      y: new Date(entry[1]),
    };
  });

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={10}
      padding={{ left: 100, bottom: 60, right: 25 }}
      width={500}
      height={350}
    >
      <VictoryAxis
        label="Purchase Date"
        tickCount={5}
        tickFormat={(x: string) => {
          const date = new Date(x);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          return `${year}-${month}`;
        }}
        style={{ ...sharedAxisStyles, ...sharedLabelStyles }}
      />
      <VictoryAxis
        dependentAxis
        label="Carbon purchased (kg)"
        style={{ ...sharedAxisStyles, ...sharedLabelStyles }}
        axisLabelComponent={<VictoryLabel dy={-25} />}
      />
      <VictoryLine data={dataPoints} style={{ data: { stroke: '#2F855A' } }} />
      <VictoryScatter data={dataPoints} style={{ data: { fill: '#2F855A' } }} />
    </VictoryChart>
  );
};

export default DashboardContributionChart;
