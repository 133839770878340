import React from 'react';

import DashboardStatCard from './DashboardStatCard';
import { calculateKgCarbonRemoval } from '../../../utils/aggregations';
import { Invoice } from '../../../types/interfaces';

const ContributionsToDateKgCarbonRemoval = (props: { invoices: Invoice[] }) => {
  const { totalKgCarbonRemoval, totalKgCarbonRemovalCurrentYear } =
    calculateKgCarbonRemoval(props.invoices);

  const data = {
    label: 'Contributions to date (kg)',
    tabs: [
      {
        listTitle: 'kilos (kg)',
        statNumber: `${totalKgCarbonRemoval.toLocaleString()} kg`,
        isIncrease: true,
        changeAmountString: `${totalKgCarbonRemovalCurrentYear.toLocaleString()} kg in ${new Date().getFullYear()}`,
      },
      {
        listTitle: 'tonnes (t)',
        statNumber: `${totalKgCarbonRemoval / 1000} t`,
        isIncrease: true,
        changeAmountString: `${
          totalKgCarbonRemovalCurrentYear / 1000
        } t in ${new Date().getFullYear()}`,
      },
    ],
  };

  return <DashboardStatCard label={data.label} tabs={data.tabs} />;
};

export default ContributionsToDateKgCarbonRemoval;
