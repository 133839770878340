import { Badge } from '@chakra-ui/react';

const NumBadge = (props: {
  num: number;
  bgColor?: string;
  textColor?: string;
}) => {
  return (
    <Badge
      bg={props.bgColor || 'white'}
      color={props.textColor || 'black'}
      borderRadius="full"
    >
      {props.num}
    </Badge>
  );
};

export default NumBadge;
