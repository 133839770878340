import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Center,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

import { UserBadge, UserBadgeInfo } from '../../types/interfaces';
import UnseenAchievementBadge from './UnseenAchievementBadge';
const AchievementCard = (props: {
  badgeEarned: UserBadge | undefined;
  badgeInfo: UserBadgeInfo;
}) => {
  const renderBadgeEarnedText = () => {
    if (props.badgeEarned) {
      return `Earned on ${new Date(props.badgeEarned.awardedAt).toLocaleDateString()}`;
    }
    return ' ';
  };

  return (
    <Card alignItems="center" borderRadius="2xl" position="relative">
      {props.badgeEarned && !props.badgeEarned.seen && (
        <UnseenAchievementBadge />
      )}

      <CardHeader>
        <Heading mt="16px" size="sm">
          {props.badgeInfo.name}
        </Heading>
        {
          <Box pt="8px" h="0.75rem">
            <Text fontSize="0.75rem" textAlign="center">
              {renderBadgeEarnedText()}
            </Text>
          </Box>
        }
      </CardHeader>
      <CardBody paddingX={6} paddingTop={0}>
        <Center paddingX={2}>
          <Image
            src={props.badgeInfo.imageLink}
            w={200}
            h={250}
            filter={props.badgeEarned ? 'none' : 'grayscale(100%)'}
            mb="-20px"
          />
        </Center>
        <Stack spacing={4}>
          <Text fontSize="1rem" textAlign="center">
            {props.badgeInfo.description}
          </Text>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default AchievementCard;
