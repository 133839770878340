import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Center, HStack, Image, VStack } from '@chakra-ui/react';

import { Icon, StarIcon, SunIcon } from '@chakra-ui/icons';
import { FaBookOpen, FaHome, FaTrophy } from 'react-icons/fa';

import NavbarItem from './NavbarItem';
import LoginButton from '../buttons/LoginButton';
import LogoutButton from '../buttons/LogoutButton';
import { getUserBadges } from '../../services/user_badges';
import { UserBadge } from '../../types/interfaces';
import NumBadge from '../badges/NumBadge';

const Navbar = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    unseenBadgesData: {
      numUnseenBadges: 0,
      isLoading: true,
    },
  });

  // Fetch User Badge data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();

        const response = await getUserBadges(token);

        const badges = await response.json();
        const numUnseenBadges = badges.filter(
          (badge: UserBadge) => !badge.seen,
        ).length;

        if (response.ok) {
          setState((prevState) => ({
            ...prevState,
            unseenBadgesData: { numUnseenBadges, isLoading: false },
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          unseenBadgesData: { numUnseenBadges: 0, isLoading: false },
        }));
      }
    };

    fetchData();
  }, [getAccessTokenSilently, setState]);

  return (
    <Box
      w="180px"
      position="fixed"
      top={0}
      marginLeft={2}
      height="100vh"
      bg="white"
      zIndex="1000"
    >
      <Center marginY="1rem">
        <Image
          src="https://cdn.dorik.com/tmp/images/Zopeful_logo_1-(1)_w8jliv29.png"
          w="10rem"
          h="2.5rem"
          alt="Zopeful Climate Logo"
        />
      </Center>

      {!isAuthenticated && (
        <Box alignItems="left" minW="12rem">
          <LoginButton />
        </Box>
      )}

      {isAuthenticated && (
        <Center alignItems="center">
          <VStack>
            {NavbarItem('/dashboard', 'Dashboard', <Icon as={FaHome} />)}
            {NavbarItem('/contributions', 'Contributions', <StarIcon />)}
            {NavbarItem('/learning', 'Learning Hub', <Icon as={FaBookOpen} />)}
            {NavbarItem('/purchase', 'Purchase CDR', <SunIcon />)}
            <HStack>
              {NavbarItem(
                '/achievements',
                'Achievements',
                <Icon as={FaTrophy} />,
              )}
              {state.unseenBadgesData.numUnseenBadges > 0 && (
                <NumBadge
                  num={state.unseenBadgesData.numUnseenBadges}
                  bgColor="green"
                  textColor="white"
                />
              )}
            </HStack>
          </VStack>
          <Center minW="12rem" bottom="5vh" position="absolute">
            <LogoutButton />
          </Center>
        </Center>
      )}
    </Box>
  );
};

export default Navbar;
